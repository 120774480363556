import * as R from "ramda";
import { isSuccesfullApiCall, notEmpty } from "../lib/utils";
import { getAgentRole, logError } from "./helper";
import { openInitErrorModal, openModal } from "../state/modal";
import { notGuideRep } from "../lib/AgentState";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

const MISSING_RECORDING_MODAL = "MISSING_RECORDING_MODAL";

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      const store = getState();
      const agent = extractAgentFromStore(store);
      if (notGuideRep(agent)) {
        return;
      }
      const request_params = {
        routing_role: getAgentRole(agent),
      };
      try {
        const response = await getDeltaAxiosInstance().post(
          "fetch_missing_intro_recordings",
          request_params,
        );
        if (isSuccesfullApiCall(response)) {
          const recordings = R.propOr([], "data", response);
          if (notEmpty(recordings)) {
            dispatch(
              openModal(
                MISSING_RECORDING_MODAL,
                { recordings },
                { disableBackdropClick: true, disableEscapeKeyDown: true },
              ),
            );
          }
        } else {
          dispatch(openInitErrorModal());
        }
      } catch (error) {
        logError(error);
        dispatch(openInitErrorModal());
      }
    }
  };
