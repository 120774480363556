import * as R from "ramda";

import { configureStore } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import { createLogger } from "redux-logger";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { isVideoRoom } from "../lib/utils";
import reducers from "../state";
import AutoAccept from "./AutoAccept";
import AutoBlockAgents from "./AutoBlockAgents";
import CheckCallOnHoldHungup from "./CheckCallOnHoldHungup";
import CheckClientDisconnected from "./CheckClientDisconnected";
import CheckClientsReady from "./CheckClientsReady";
import CheckLatestVersion from "./CheckLatestVersion";
import { checkUserIsActive } from "./CheckUserIsActive";
import CloseDispositionModal from "./CloseDispositionModal";
import CookieHandler from "./CookieHandler";
import FetchActiveTasks from "./FetchActiveTasks";
import FetchAgentSchedule from "./FetchAgentSchedule";
import FetchAgentVoicemails from "./FetchAgentVoicemails";
import FetchCrierNotifications from "./FetchCrierNotifications";
import FetchDispositions from "./FetchDispositions";
import FetchIntercomUserHash from "./FetchIntercomUserHash";
import FetchMissingIntroRecordings from "./FetchMissingIntroRecordings";
import FetchTransferPartners from "./FetchTransferPartners";
import HandleNewConnection from "./HandleNewConnection";
import Logging from "./Logging";
import NewMissedCallsModalPopup from "./NewMissedCallsModalPopup";
import PublishBrowserInfo from "./PublishBrowserInfo";
import SetTimezone from "./SetTimezone";
import TestCallCheck from "./TestCallCheck";

const pickPaths = (paths, obj) =>
  R.reduce((o, p) => R.assocPath(p, R.path(p, obj), o), {}, paths);

// TODO Kush Change paths for supervisor
const statePaths = [
  ["activeCall", "conference"],
  ["agentCallbacks"],
  ["agentVoicemails"],
  ["modal"],
  ["supervisor"],
  ["dispositions"],
  ["transferPartners"],
  ["conversations"],
  ["voice", "client", "_status"],
  ["voice", "connection", "_status"],
  ["voice", "connectionError"],
  ["voice", "connectionState"],
  ["sync", "connectionState"],
  ["sync", "connectionError"],
  ["supervisor", "action"],
  ["supervisor", "actionStatus"],
  ["supervisor", "supervising"],
  ["supervisor", "taskSid"],
  ["supervisor", "view"],
  ["worker", "activity"],
  ["worker", "attributes", "assuranceId"],
  ["worker", "attributes", "assuranceRolesStr"],
  ["worker", "attributes", "lineOfInsurance"],
  ["worker", "attributes", "phoneNumber"],
  ["worker", "attributes", "roles"],
  ["worker", "attributes", "contactUri"],
  ["worker", "attributes", "workOrder"],
  ["worker", "attributes", "workerSid"],
  ["centralIntelligence"],
];

const mainHeaderPaths = [
  ["type"],
  ["tag"],
  ["payload", "activeCall", "conference"],
  ["payload", "activeCall", "lead", "id"],
  ["payload", "activeCall", "lead", "campaignName"],
  ["payload", "activeCall", "lead", "insuranceLine"],
  ["payload", "activeCall", "lead", "queuingId"],
  ["payload", "activeCall", "task", "sid"],
  ["payload", "activeCall", "task", "status"],
  ["payload", "activeCall", "task", "dialDirection"],
  ["payload", "activeCall", "task", "executionPlanIds"],
  ["payload", "agent", "workerSid"],
];

const isLogMainHeader = R.pipe(R.prop("type"), R.equals("log/MAIN_HEADER"));

const filterMainHeaderAction = R.partial(pickPaths, [mainHeaderPaths]);

const getTaskAttributePaths = (taskSid) => [
  ["taskAttributes", taskSid, "conference"],
  ["taskAttributes", taskSid, "dial_direction"],
  ["taskAttributes", taskSid, "disposition"],
  ["taskAttributes", taskSid, "lead", "campaign_name"],
  ["taskAttributes", taskSid, "lead", "id"],
  ["taskAttributes", taskSid, "lead", "queuingId"],
];

const isChatAction = R.pipe(R.prop("type"), R.startsWith("twilio-chat"));

const addStateTaskPaths = R.pipe(
  R.prop("taskAttributes"),
  R.keys,
  R.map(getTaskAttributePaths),
  R.unnest,
  R.partial(R.concat, [statePaths]),
);

const filterState = (state) => pickPaths(addStateTaskPaths(state), state);

const filterAction = R.cond([
  [isLogMainHeader, filterMainHeaderAction],
  [isChatAction, R.always(null)],
  [R.T, R.identity],
]);

const getLogger = () => {
  if (R.equals(process.env.REACT_APP_LOGROCKET_ENABLED, "true")) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_API_KEY, {
      mergeIframes: true,
    });
    return LogRocket.reduxMiddleware({
      stateSanitizer: filterState,
      actionSanitizer: filterAction,
    });
  } else {
    return createLogger();
  }
};

const VIDEO_ROOM_MIDDLEWARES = [
  CheckLatestVersion,
  Logging,
  checkUserIsActive,
  thunk,
  promise,
  getLogger(),
];

const DIALER_MIDDLEWARE = [
  CheckLatestVersion,
  TestCallCheck,
  CheckClientsReady,
  CheckClientDisconnected,
  CheckCallOnHoldHungup,
  HandleNewConnection,
  FetchAgentSchedule,
  FetchMissingIntroRecordings,
  FetchDispositions,
  FetchAgentVoicemails,
  FetchCrierNotifications,
  FetchIntercomUserHash,
  FetchTransferPartners,
  FetchActiveTasks,
  AutoBlockAgents,
  Logging,
  AutoAccept,
  PublishBrowserInfo,
  SetTimezone,
  NewMissedCallsModalPopup,
  CookieHandler,
  CloseDispositionModal,
  checkUserIsActive,
  thunk,
  promise,
  getLogger(),
];

const middlewares = isVideoRoom ? VIDEO_ROOM_MIDDLEWARES : DIALER_MIDDLEWARE;

export const store = configureStore({
  reducer: reducers,
  middleware: (gdm) =>
    gdm({ serializableCheck: false, immutableCheck: false }).concat([...middlewares]),
  devTools: process.env.NODE_ENV !== "production",
});
